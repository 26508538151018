import React, { useEffect } from 'react';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import ErrorPage from 'next/error';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';

import initOneSegment, {
  getGenericTrackingData,
  preInit,
} from '@finanzcheck/one-segment';

import { InitOptions } from '@finanzcheck/one-segment/dist/init';
import { Tenant, getTheme } from '@finanzcheck/teal-mui-theme';

import 'styles/globals.css';
import { ErrorBoundary } from 'lib/bugsnag';
import { getSessionId } from 'lib/session';
import { trackPageView } from 'lib/tracking/track';
import { log2net } from 'lib/logging/netLogger';
import { useOptimizely, OptimizelyProvider } from 'lib/optimizely';
import { isOptimizelyAllowed } from 'lib/optimizely/isOptimizelyAllowed';
import { isTrackingAndCcmtIncluded } from 'lib/tracking/trackingExclusions';

import { LoanSelectionProvider } from 'contexts/LoanSelectionContext';
import { AnalyticsProvider } from 'contexts/AnalyticsContext';

import { ANONYMOUS_PAGE_VISIT_EVENT } from 'constants/trackingEvents';

export default function App({ Component, pageProps }: AppProps) {
  //Some page do not init tracking and cookie banner due to legal reasons
  // We also suppress the banner in tests via test=true query param
  const { query } = useRouter();
  const pathname = usePathname();
  const isCcmtAllowed = isTrackingAndCcmtIncluded(pathname, query);
  const isOptimizelyAllowedFlag = isOptimizelyAllowed(pathname, query);

  const [isAnalyticsReady, setIsAnalyticsReady] =
    React.useState<boolean>(false);

  const theme = getTheme(Tenant.Smava);

  const { refId, subId, utm_source, utm_medium, utm_campaign, utm_term } =
    query;

  useEffect(() => {
    // init the fcid session as soon as possible
    const fcId = getSessionId();

    // We track any page visit to get a ratio of users that have ccmt disabled
    const netLogPayload = {
      context: 'website-smava',
      url: pathname,
      fcId,
      refId,
      subId,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
    };
    log2net(ANONYMOUS_PAGE_VISIT_EVENT, netLogPayload);

    if (isCcmtAllowed) {
      preInit(); // Init OneSegment Queue
      setIsAnalyticsReady(true);
    }
  }, [
    isCcmtAllowed,
    pathname,
    refId,
    subId,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
  ]);

  useEffect(() => {
    // we are skipping tracking until both analytics is ready and ccmt was allowed
    if (isAnalyticsReady && isCcmtAllowed) {
      trackPageView(getGenericTrackingData());
    }
  }, [isAnalyticsReady, isCcmtAllowed]);

  const optimizely = useOptimizely(pageProps);

  const initOneSegmentOptions: InitOptions = {
    debug: process.env.NODE_ENV === 'development',
    initialPageTrack: false,
    teamName: 'watermelon',
    sessionCookieDomain: process.env.COOKIE_BASE_URL,
  };
  if (process.env.SEGMENT_HOST) {
    initOneSegmentOptions.segmentHost = process.env.SEGMENT_HOST;
  }

  const children = (
    <>
      <AnalyticsProvider value={{ isAnalyticsReady, setIsAnalyticsReady }}>
        <MUIThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <LoanSelectionProvider>
              {/* Always pass isServerSide in order to fix an unexpected behaviour in optimizely SDK: */}
              {/* Even though client is ready immediately (due to datafile and user being present), initial state is not ready >_< */}
              {isOptimizelyAllowedFlag ? (
                <OptimizelyProvider
                  optimizely={optimizely}
                  user={pageProps.user}
                  isServerSide={typeof window === 'undefined'}
                >
                  <CssBaseline />
                  <Component {...pageProps} />
                </OptimizelyProvider>
              ) : (
                <>
                  <CssBaseline />
                  <Component {...pageProps} />
                </>
              )}
            </LoanSelectionProvider>
          </EmotionThemeProvider>
        </MUIThemeProvider>
      </AnalyticsProvider>
      {isCcmtAllowed && (
        <Script
          src="https://opticksprotection.com/o/f193f3a91e17d54d"
          strategy="afterInteractive"
        />
      )}
      {isCcmtAllowed && (
        <Script
          id="init-one-segment"
          strategy="afterInteractive"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-domain-script={process.env.ONE_TRUST_SCRIPT_ID}
          onLoad={() => {
            initOneSegment(
              process.env.SEGMENT_WRITE_KEY,
              initOneSegmentOptions,
            );
          }}
        />
      )}
    </>
  );
  if (ErrorBoundary)
    return (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
    );
  return children;
}
