/**
 * Renders the NewHeroSection component.
 * This component displays a hero section with an app download button and partner banks.
 * It's a Variation D of the AppRedirection ABCD Test CAT-2766.
 *
 * @component
 * @example
 * return (
 *   <NewHeroSection styleOverrides={styleOverrides} />
 * )
 */
import React from 'react';
import Image from 'next/image';
import { ArrowForward } from '@mui/icons-material';
import { PartnerBanks } from 'components/PartnerBanks/PartnerBanks';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AppImage from 'public/assets/visuals/app-image.png';
import MobileStore from 'public/assets/visuals/app-play-store.svg';
import { TrustText } from 'components/Stage/LegalText';
import { Headline, StageStyleOverrides } from 'components/Stage/Stage';
import { redirectToCorrectStore } from './helpers';

interface Props {
  styleOverrides?: StageStyleOverrides;
}

const NewHeroSection: React.FC<Props> = (props) => {
  return (
    <>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        mt={(theme) => theme.spacing(2)}
        flexDirection="column"
        alignItems="center"
      >
        <Image src={MobileStore} alt="mobile_store" style={{ width: '100%' }} />
        <Box my={(theme) => theme.spacing(2)} width="100%">
          <Button
            data-test-id="CTA"
            variant="contained"
            fullWidth
            color="primary"
            type="button"
            onClick={redirectToCorrectStore}
            endIcon={<ArrowForward />}
          >
            Jetzt die App herunterladen
          </Button>
        </Box>

        <Box
          mt={(theme) => theme.spacing(2)}
          bgcolor="linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 100%"
          width={(theme) => `calc(100% + ${theme.spacing(2)})`}
        >
          <Image
            src={AppImage}
            alt="app_image"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        <Box
          mt={(theme) => theme.spacing(1)}
          style={{ alignSelf: 'flex-start' }}
        >
          <TrustText>
            &#42;Weitere Informationen sowie die Garantie-Bedingungen finden Sie{' '}
            <a href="https://www.smava.de/kredit/guenstiger">hier</a>
          </TrustText>
        </Box>
        <PartnerBanks />
      </Box>

      <Headline variant="h1" sx={props.styleOverrides?.headline}>
        Persönliche Angebote in 3 Minuten erhalten
      </Headline>
    </>
  );
};

export { NewHeroSection };
