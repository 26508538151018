import { UserProfile, UserProfileService } from '@optimizely/optimizely-sdk';
import { setCookie } from '../cookies/helper';
import Bugsnag from '../bugsnag';

export const VARIATION_ASSIGNMENT_COOKIE = 'opuva';
const COOKIE_MAX_AGE_SECONDS = 60 * 60 * 24 * 365;
const ASSIGNMENT_AGE_MS = 1000 * 60 * 60 * 24 * 31;
const MAX_PARALLEL_ASSIGNMENTS: number = 100;

const AssignmentSeparator = '~';
const FieldSeparator = '_';

// we need to use an internal type to store variation assignments
// so we can include a timestamp for housekeeping purposes since
// we're using a single cookie to store all experiment assignments
// so we need a timestamp to expire old assignment
interface VariationAssignment {
  [experimentId: string]: {
    variationId: string;
    timestamp: number;
  };
}

/**
 * uses a single cookie to store user variation assignments.
 * since there is a limit size of a single cookie, the serialization
 * format has to be compact.
 * we use a simple string concatenation to store 3 fields per experiment
 * experimentId_variationId_timestamp
 * and we'll have as many of these strings joined with a '~' character as there are experiments active for this user.
 * timestamp refers to the first activation time and will be used for pruning older entries.
 */
export class CookieBasedProfileService implements UserProfileService {
  constructor(private serializedAssignments: string) {}

  lookup(userId: string): UserProfile {
    const assignments = this.parseAssignments();
    return toUserProfile(userId, assignments);
  }

  save(profile: UserProfile): void {
    const assignments = toVariationAssignment(profile, this.parseAssignments());
    this.storeAssignments(
      this.limitParallelAssignments(assignments, MAX_PARALLEL_ASSIGNMENTS),
    );
  }

  private parseAssignments(): VariationAssignment {
    const assignments: VariationAssignment = {};
    if (this.serializedAssignments.length < 1) return assignments;
    try {
      const items = this.serializedAssignments.split(AssignmentSeparator);
      items.forEach((item) => {
        if (item.length < 1) return;
        const [experimentId, variationId, timestampString] =
          item.split(FieldSeparator);
        const timestamp = parseInt(timestampString);
        // skip old assignments
        if (timestamp && Date.now() - timestamp > ASSIGNMENT_AGE_MS) {
          return;
        }
        assignments[experimentId] = {
          variationId,
          timestamp,
        };
      });
      return assignments;
    } catch (e) {
      console.log(
        'error parsing variation assignments',
        e,
        this.serializedAssignments,
      );
      Bugsnag.notify(new Error('error parsing variation assignments'));
    }
    return {};
  }

  private storeAssignments(assignments: VariationAssignment): void {
    if (typeof window === 'undefined') return; // don't store cookie serverside
    const serialized = Object.keys(assignments)
      .map(
        (k) =>
          k +
          FieldSeparator +
          assignments[k].variationId +
          FieldSeparator +
          assignments[k].timestamp,
      )
      .join(AssignmentSeparator);
    setCookie(VARIATION_ASSIGNMENT_COOKIE, serialized, {
      maxAge: COOKIE_MAX_AGE_SECONDS,
      expires: new Date(Date.now() + COOKIE_MAX_AGE_SECONDS * 1000),
    });
  }

  private limitParallelAssignments(
    assignments: VariationAssignment,
    limit: number,
  ): VariationAssignment {
    if (Object.keys(assignments).length <= limit) return assignments;
    const keysSortedByTime = Object.keys(assignments)
      .sort((a, b) => assignments[b].timestamp - assignments[a].timestamp)
      .slice(0, limit);

    const prunedAssignments: VariationAssignment = keysSortedByTime.reduce(
      (carry, experimentId) => {
        return {
          ...carry,
          [experimentId]: assignments[experimentId],
        };
      },
      {},
    );

    return prunedAssignments;
  }
}

// a helper to convert Optimizely UserProfile type to our internal VariationAssignment w timestamp
function toVariationAssignment(
  userProfile: UserProfile,
  existingAssignments: VariationAssignment,
): VariationAssignment {
  const assignments: VariationAssignment = { ...(existingAssignments || {}) };
  for (const k of Object.keys(userProfile.experiment_bucket_map)) {
    assignments[k] = {
      variationId: userProfile.experiment_bucket_map[k].variation_id,
      timestamp:
        existingAssignments && existingAssignments[k]
          ? existingAssignments[k].timestamp
          : +new Date(),
    };
  }
  return assignments;
}

// a helper to convert internal VariationAssignment w timestamp to Optimizely UserProfile type
function toUserProfile(
  userId: string,
  assignments: VariationAssignment,
): UserProfile {
  const profile: UserProfile = {
    user_id: userId,
    experiment_bucket_map: {},
  };
  for (const k of Object.keys(assignments)) {
    profile.experiment_bucket_map[k] = {
      variation_id: assignments[k].variationId,
    };
  }
  return profile;
}
