export const BLACK_FRIDAY_FLAG = 'black_friday_flag_variable'

export enum ABTestKeys {
  CAT2766_app_redirectionABCD = '2024-06-12_cat-2766_app_redirection_v2',
  CAT3106_Black_friday_banner = '2024-11-05_cat-3106_black_friday_flag',
}

export const ABTestsConfig: { [key in ABTestKeys]: string[] } = {
  [ABTestKeys.CAT2766_app_redirectionABCD]: [
    'control',
    'variation_b',
    'variation_c',
    'variation_d',
  ],
  [ABTestKeys.CAT3106_Black_friday_banner]: [BLACK_FRIDAY_FLAG],
};

