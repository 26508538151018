/**
 * Renders the TopBanner component.
 * It's a Variation B of the AppRedirection ABCD Test CAT-2766.
 *
 * @component
 * @example
 * return (
 *   <TopBanner />
 * )
 */
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getRedirectionLink, trackAppRedirectionClick } from './helpers';

const TopBanner: React.FC = () => {
  const [redirectionLink, setRedirectionLink] = React.useState<string>('');

  React.useEffect(() => {
    setRedirectionLink(getRedirectionLink());
  }, []);

  return (
    <Box
      display={{ xs: 'block', md: 'none' }}
      width="100%"
      py={(theme) => theme.spacing(1)}
      px={(theme) => theme.spacing(2)}
      mb={(theme) => theme.spacing(2)}
      border={(theme) => `1px solid ${theme.palette.grey[100]}`}
      borderRadius="20px"
      bgcolor={(theme) => theme.palette.grey[50]}
    >
      <Typography
        variant="subtitle2"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={(theme) => theme.spacing(0.5)}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <BoltIcon fontSize="small" />
          Schneller zum Kredit!
        </Box>
        <Link
          onClick={trackAppRedirectionClick}
          href={redirectionLink}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ textDecoration: 'none' }}
        >
          App herunterladen <ChevronRightIcon fontSize="small" />
        </Link>
      </Typography>
    </Box>
  );
};

export { TopBanner };
