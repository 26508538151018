import { getGenericTrackingData } from '@finanzcheck/one-segment';
import { ANDROID, IOS, getOsName } from 'lib/router/route-helper';
import { trackButtonClick } from 'lib/tracking/track';
import { TrackingEvent } from 'lib/tracking/events';
import { USER_ID_COOKIE_NAME } from 'lib/optimizely/getUserId';
import { getCookie } from 'lib/cookies/helper';

export const trackAppRedirectionClick = () => {
  trackButtonClick(TrackingEvent.REDIRECT_TO_APP, {
    device: getOsName(),
    ...getGenericTrackingData(),
  });
};

export const getRedirectionLink = () =>
  `https://app.adjust.com/ahuyru9?campaign=test_2024-06-17&label=${getCookie(
    USER_ID_COOKIE_NAME,
  )}`;

export const redirectToCorrectStore = () => {
  trackAppRedirectionClick();

  const osName = getOsName();
  if (osName === IOS || osName === ANDROID) {
    const redirectionLink = getRedirectionLink();
    window.location.href = redirectionLink;
  }
};
