/**
 * Renders the Overlay component.
 * It's a (part of) Variation C of the AppRedirection ABCD Test CAT-2766.
 * (the other part is the TopBanner Variation B component)
 *
 * @component
 * @example
 * return (
 *   <Overlay />
 * )
 */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Box, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MobileStore from 'public/assets/visuals/app-play-store.svg';
import { getLocalStorage, setLocalStorage } from 'lib/storage/storage';
import { STORAGE_CONSTANTS } from 'lib/constants/storage';
import { redirectToCorrectStore } from './helpers';

const Overlay: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(
      getLocalStorage(STORAGE_CONSTANTS.isAppOverlayClosed) !== String(true),
    );
  }, []);

  const handleRedirectClick = () => {
    redirectToCorrectStore();
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
    setLocalStorage(STORAGE_CONSTANTS.isAppOverlayClosed, String(true));
  };

  useEffect(() => {
    if (isOpen) {
      window.document.body.style.overflow = 'hidden';
      return;
    }
    window.document.body.style.overflow = 'auto';
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display={{ xs: 'flex', md: 'none' }}
      flexDirection="column"
      justifyContent="flex-end"
      overflow="auto"
      bgcolor={(theme) => theme.palette.common.white}
      zIndex="1"
    >
      <Box
        onClick={handleClose}
        position="absolute"
        top="0"
        right="0"
        p={(theme) => theme.spacing(2)}
        color={(theme) => theme.palette.grey[900]}
        sx={{ cursor: 'pointer' }}
      >
        <CloseIcon fontSize="large" />
      </Box>
      <Box
        width="100%"
        height="100%"
        sx={{
          backgroundImage: `url(assets/images/WomanBusStopCellPhone-min.webp)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          backgroundSize: 'cover',
        }}
      ></Box>

      <Box p={(theme) => theme.spacing(3)}>
        <Typography variant="h3" mb={(theme) => theme.spacing(1)}>
          App laden und Angebote zum Topzins erhalten.
        </Typography>
        <Typography variant="body1">
          Mit der smava App noch schneller zum Kredit von unterwegs.
        </Typography>
        <Box my={(theme) => theme.spacing(2)} width="100%">
          <Button
            data-test-id="CTA"
            variant="contained"
            fullWidth
            color="primary"
            type="button"
            onClick={handleRedirectClick}
          >
            kostenlos herunterladen
          </Button>
        </Box>
        <Image src={MobileStore} alt="mobile_store" style={{ width: '100%' }} />
      </Box>
    </Box>
  );
};

export { Overlay };
